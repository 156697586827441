<template>
  <WeContainer card="" v-if="ready">
    <div class="row">
      <div class="col-12 col-lg-2 sticky-tabs">
        <Navigation
          v-bind:tabs="tabs"
          v-bind:activeTab="this.$route.params.id"
        />
      </div>
      <div class="col">
        <WeCard class="sticky-header shadow-sm">
          <div class="row align-items-center">
            <div class="col">
              <div class="sticky-header-content">
                <span>{{ headerTitle }}</span>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex">
                <button
                  class="btn btn-primary mr-3"
                  v-if="this.$route.params.id == 'category'"
                  v-on:click="selectCategory"
                >
                  <i class="fas fa-search"></i>
                  <span class="ml-2">Kategori Seç</span>
                </button>
                <div class="d-flex">
                  <WeSubmitButton
                    v-bind:submit-status="submit"
                    v-on:submit="saveBlocks"
                  />
                </div>
              </div>
            </div>
          </div>
        </WeCard>
        <WeCard v-if="contentVisible" class="shadow-sm mt-4 sticky-content">
          <WeSearchInput
            class="mb-0 product-select"
            v-bind:name="getSearchInputInfo.name"
            v-bind:route="getSearchInputInfo.route"
            v-bind:additional="getSearchInputInfo.category_id"
            v-bind:placeholder="getSearchInputInfo.placeholder"
            v-bind:result-prop="getSearchInputInfo.resultProp"
            v-bind:language="getSearchInputInfo.language || 'tr'"
            v-on:on-select="productSelect"
          />
          <template
            v-if="
              getSearchInputInfo.name == 'product' ||
              getSearchInputInfo.name == 'categoryProduct'
            "
          >
            <draggable v-model="products">
              <transition-group name="block" type="transition">
                <div
                  v-for="product in products"
                  :key="product.id"
                  v-bind:class="[
                    'product-card',
                    { 'alert-danger': product.quantity < 1 },
                  ]"
                >
                  <i class="fa fa-arrows-alt" />
                  <img v-bind:src="product.image" />
                  <div class="product-info">
                    <span class="product-info-name">{{ product.name }} | {{ product.sku_no }}</span>
                    <div class="product-info-wrapper">
                      <span class="product-info-quantity"
                        ><b>Miktar:</b> {{ product.quantity }}</span
                      >
                      <span class="indicator">|</span>
                      <span class="product-info-price"
                        ><b>Fiyat:</b> {{ product.price }}</span
                      >
                      <template
                        v-if="
                          product.discountPrice && product.discountPrice > 0
                        "
                      >
                        <span class="indicator">|</span>
                        <span class="product-info-discount"
                          ><b>İndirimli Fiyat:</b>
                          {{ product.discountPrice }}</span
                        >
                      </template>
                      <template v-if="product.quantity < 1">
                        <span class="indicator">|</span>
                        <small class="font-12 text-danger"
                          ><b
                            >Ürün stokta bulunmadığı için vitrinde
                            gösterilmez</b
                          ></small
                        >
                      </template>
                    </div>
                  </div>
                  <a
                    class="btn ml-3 product-trash"
                    v-on:click="removeProduct(product.id)"
                  >
                    <i class="fa fa-trash mr-2" />
                  </a>
                </div>
              </transition-group>
            </draggable>
          </template>
          <template v-if="getSearchInputInfo.name == 'banner'">
            <draggable v-model="banners">
              <transition-group name="block" type="transition">
                <div
                  v-for="banner in banners"
                  :key="banner.id"
                  class="product-card"
                >
                  <i class="fa fa-arrows-alt" />
                  <img v-bind:src="banner.image" />
                  <div class="product-info">
                    <span class="product-info-name">{{
                      banner.description
                    }}</span>
                  </div>
                  <a
                    class="btn ml-3 product-trash"
                    v-on:click="removeBanner(banner.id)"
                  >
                    <i class="fa fa-trash mr-2" />
                  </a>
                </div>
              </transition-group>
            </draggable>
          </template>
        </WeCard>
      </div>
    </div>
    <WeModal
      modalWidth="30"
      title="Kategori Seç"
      class="categoryModal"
      v-if="categoryModal"
      v-on:close="closeModal"
    >
      <div slot="body">
        <WeCategory
          v-bind:current-category="showcaseDetail.category_id"
          v-bind:multiple="false"
          v-bind:draggable="true"
          v-bind:showSelectedCategories="false"
          v-bind:placeholder="$t('searchCategory')"
          v-bind:language="showcaseManagement.lang"
          ref="categorySelector"
        />
        <button
          class="btn btn-primary ml-auto d-block mt-4"
          v-on:click="selectCategorySubmit"
        >
          <i class="fa fa-check mr-2" />
          <span>Seç</span>
        </button>
      </div>
    </WeModal>
  </WeContainer>
  <WeLoading v-else />
</template>
<style lang="scss">
.categoryModal .custom-card-title {
  display: none;
}
.categoryModal .card {
  border: none;
}
.categoryModal .modal-body {
  padding: 0;
}
.categoryModal .card-body {
  padding: 0;
}
.sticky-tabs {
  position: sticky;
  top: -2px;
  height: max-content;
  margin-bottom: -16px;
}
.sticky-header {
  top: -2px;
  position: sticky;
  z-index: 1;
}
.sticky-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.sticky-header-content > span {
  font-size: 17px;
  font-weight: 600;
  width: 100%;
}
.sticky-header-content button {
  flex-shrink: 0;
}
.product-card {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: grab;
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.product-card:hover {
  background-color: #fbfbfb;
}
.product-card > i {
  color: #3f51b5;
  margin-right: 24px;
  font-size: 16px;
}
.product-card img {
  width: 56px;
  height: 56px;
}
.product-info {
  width: 100%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.product-info-wrapper {
  display: flex;
  gap: 10px;
  align-content: center;
  align-items: center;
  margin-top: 4px;
}

.product-info-name {
  font-size: 16px;
  font-weight: 500;
}

.product-info-quantity,
.product-info-price,
.product-info-discount {
  font-size: 14px;
  font-weight: 500;
  color: #697481;
}

.sticky-content {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}
.product-select input {
  border: 1px solid #80bdff !important;
}
.product-trash {
  background: #fef5f7;
  color: #dc3545;
}

.product-trash:hover {
  background: #dc3545;
  color: #fff;
}

.product-trash i {
  margin: 0 !important;
}

.indicator {
  color: #d3d3d3;
  padding-bottom: 1px;
}
</style>
<script>
import Navigation from "../Navigation/Index";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "Edit",
  data() {
    return {
      headerTitle: "Vitrini Yönetimi",
      ready: false,
      categoryModal: false,
      tabs: [],
      showcaseDetail: {},
      contentVisible: true,
      products: [],
      banners: [],
      submit: false,
    };
  },
  components: {
    Navigation,
    draggable,
  },
  methods: {
    ...mapActions("showcaseManagement", ["getHomeBlocks", "saveShowcase"]),
    selectCategory() {
      this.categoryModal = true;
    },
    selectCategorySubmit() {
      this.headerTitle = this.$refs.categorySelector.selected[0].name;
      this.categoryModal = false;
      this.contentVisible = true;
      // this.ready = false;
    },
    closeModal() {
      this.categoryModal = false;
    },
    productSelect(data) {
      switch (this.getSearchInputInfo.name) {
        case "categoryProduct":
        case "product":
          const checkProduct = this.products.findIndex((p) => p.id == data.id);
          if (checkProduct >= 0) {
            this.$toast.info("Bu ürün listede mevcut");
          } else if (data.quantity < 1) {
            this.$toast.info("Stokta olmayan ürün eklenemez");
          } else {
            this.products.unshift({
              name: data.name,
              image: data.image,
              id: data.id,
              price: localization.getMoneyFormat(
                data.currency_code.code,
                data.price
              ),
              discountPrice:
                data.discount_price > 0
                  ? localization.getMoneyFormat(
                      data.currency_code.code,
                      data.discount_price
                    )
                  : 0,
              quantity: data.quantity + " " + data.quantity_type,
            });
          }
          break;

        case "banner":
          const checkBanner = this.banners.findIndex((p) => p.id == data.id);
          if (checkBanner == -1) {
            data.image = data.image && data.image.path && data.image.path.thumb;
            this.banners.push(data);
          } else {
            this.$toast.info("Bu banner listede mevcut");
          }
          break;
      }
    },
    removeProduct(id) {
      this.$swal({
        title: "Bu ürünü vitrinden kaldırmak istediğinizden emin misiniz?",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
        icon: "warning",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.products = this.products.filter(function (obj) {
            return obj.id !== id;
          });
        }
      });
    },
    removeBanner(id) {
      this.$swal({
        title: "Bu bannerı vitrinden kaldırmak istediğinizden emin misiniz?",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
        icon: "warning",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.banners = this.banners.filter(function (obj) {
            return obj.id !== id;
          });
        }
      });
    },
    saveBlocks() {
      this.submit = true;
      this.saveShowcase({
        id: this.$route.params.id,
        showcase: this.showcaseDetail,
        products: helper.clone(this.products || []),
        banners: helper.clone(this.banners || []),
        onSuccess: () => {
          this.$toast.success("Vitrin Güncellendi");
        },
        onError: () => {
          this.$toast.error("Vitrin Güncellenemedi");
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    load() {
      this.getHomeBlocks({
        onSuccess: (result) => {
          this.tabs = result?.data.items;
        },
        onFinish: () => {
          if (this.$route.params.id !== "category") {
            this.getHomeBlocks({
              id: this.$route.params.id,
              onSuccess: (result) => {
                const data = result.data;
                if (data.status !== "error") {
                  this.headerTitle = `${data.title} Vitrin Yönetimi`;
                  this.products =
                    data.products &&
                    data.products.map((product) => {
                      product.price = localization.getMoneyFormat(
                        product?.currency_code.code,
                        product.price
                      );
                      product.discountPrice = localization.getMoneyFormat(
                        product?.currency_code.code,
                        product.discount_price
                      );

                      return product;
                    }, []);
                  this.banners = data.banners || [];
                  this.showcaseDetail = data;
                }
              },
              onFinish: () => {
                this.ready = true;
              },
            });
          }
        },
      });
    },
    onShowcaseLanguageChange(e) {
      this.showcaseManagement.lang = e;
      localStorage.setItem("showcaseManagement_lang", e);
    },
  },
  mounted() {
    this.showcaseManagement.lang = this.showcaseLanguage;
    this.load();
  },
  computed: {
    ...mapState(["showcaseManagement"]),
    getSearchInputInfo() {
      const data = {
        resultProp: "fullName",
      };

      switch (this.showcaseDetail.type) {
        case "category":
          data.name = "categoryProduct";
          data.route = "categoryProduct";
          data.category_id = this.showcaseDetail.category_id;
          data.placeholder = this.$t("searchProduct");
          break;

        case "banner":
          data.name = "banner";
          data.route = "banner";
          data.resultProp = "description";
          data.category_id = null;
          data.placeholder = this.$t("searchBanner");
          data.language =
            localStorage.getItem("showcaseManagement_lang") || "tr";
          break;

        default:
          data.name = "product";
          data.route = "product";
          data.category_id = null;
          data.placeholder = this.$t("searchProduct");
          break;
      }

      return data;
    },
    showcaseLanguage() {
      const showcaseManagementLang = localStorage.getItem(
        "showcaseManagement_lang"
      );
      return showcaseManagementLang !== this.showcaseManagement.lang
        ? showcaseManagementLang
        : this.showcaseManagement.lang;
    },
  },
  watch: {
    "showcaseManagement.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.load();
        }
      },
    },
  },
};
</script>
